import React, { createContext, useContext, useCallback, useRef, useState } from 'react';
import { reducer, initialState, Action, State } from 'store';
import { IContext, ThunkAction, ThunkDispatch } from './AppProviderModels';

export const AppContext = createContext<IContext>({
    state: { ...initialState },
    dispatch: () => initialState,
});

export const useAppContext = () => useContext(AppContext);

interface IAppProviderProps {
    children?: React.ReactNode;
}

export const AppProvider: React.FC<IAppProviderProps> = (props) => {
    const { children } = props;
    const [state, dispatch] = useThunkReducer(reducer, initialState);
    const context = {
        state,
        dispatch,
    };

    return (
        <AppContext.Provider value={context}>
            {children}
        </AppContext.Provider>
    );
};

const useThunkReducer = (reducer: (state: State, action: Action) => State, initialArg: State): [State, ThunkDispatch] => {
    const [hookState, setHookState] = useState(initialArg);

    // State management.
    const state = useRef(hookState);
    const getState = () => state.current;
    const setState = useCallback<(newState: State) => void>((newState) => {
        state.current = newState;
        setHookState(newState);
    }, [state, setHookState]);

    // Reducer.
    const reduce = useCallback<(action: Action) => State>((action) => {
        return reducer(getState(), action);
    }, [reducer, getState]);

    // Augmented dispatcher.
    const dispatch: ThunkDispatch = useCallback((action: Action | ThunkAction) => {
        return typeof action === 'function'
            ? action(dispatch, getState)
            : setState(reduce(action));
    }, [getState, setState, reduce]);

    return [hookState, dispatch];
};
