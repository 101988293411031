import { normalize } from 'normalizr';
import { ThunkAction } from 'components/common/AppProvider';
import { ReferentialsApi, PictureCollectionApiSchema } from 'services/ApiService';
import { Sector } from 'services/ApiService/PictureCollection/PictureCollectionApiClient';
import { logError } from 'services/Logger';
import { PromiseStore } from 'services/PromiseStore';
import { mergePictureCollectionEntities } from 'store/Normalizr/NormalizrAction';
import {
    createFetchSectorsAction,
    createFetchSectorsSuccessAction,
    createFetchSectorsFailureAction,
} from './SectorsActions';

export const getSectors = (): ThunkAction<Promise<Sector[]>> => async (dispatch, getState) => {
    const state = getState();

    const sectorsRequest = state.sectors.sectors;
    if (sectorsRequest && (sectorsRequest.isFetching || !sectorsRequest.didInvalidate)) {
        const promise = PromiseStore.get<Sector[]>('getSectors');
        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            const data = await ReferentialsApi.getSectors();
            const normalizedData = normalize(data.sectors, PictureCollectionApiSchema.SectorSchemaArray);
            dispatch(mergePictureCollectionEntities(normalizedData.entities));
            dispatch(createFetchSectorsSuccessAction(normalizedData.result));
            return data.sectors || [];
        })();

        PromiseStore.set(fetchTask, 'getSectors');

        dispatch(createFetchSectorsAction());

        return await fetchTask;
    }
    catch (error) {
        dispatch(createFetchSectorsFailureAction());
        logError(error);
        throw error;
    }
};
