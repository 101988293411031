import { setupSGWTConnect, SGWTConnectCore } from '@sgwt/connect-core';
import { configuration, SgConnectEnvironment } from 'config/constants';

declare global {
    interface Window {
        sgwtConnect?: SGWTConnectCore;
        SGWTWidgetConfiguration: { environment: SgConnectEnvironment }
    }
}


export interface SgConnectSettings {
    authorizationEndpoint: string;
    clientId: string;
    scope: string;
    environment: SgConnectEnvironment;
}

export const redirectUnauthorizedUser = (url = configuration.requestAccessUrl) => window.location.replace(url);

export const getAuthorizationHeader = (): string => loadSGConnect()?.getAuthorizationHeader?.() ?? '';

export const initSGConnect = (): boolean => {
    const settings = getSGConnectSettings();

    const sgConnect = loadSGConnect(settings);

    setupPassiveMode(sgConnect);

    if (sgConnect.isAuthorized()) {
        return true;
    }

    const authorizationError = sgConnect.getAuthorizationError();
    if (authorizationError) {
        throw authorizationError;
    }

    // redirects to sg-connect auth login page, which then in turn reloads the page on success
    // the getUserAccessRights function will then be called again
    // but either an error will have occurred
    // or the user will be authorized
    sgConnect.requestAuthorization();

    return false;
};

export const loadSGConnect = (settings: SgConnectSettings = getSGConnectSettings()) => {
    if (!settings.authorizationEndpoint) {
        throw new Error('[loadSGConnect] Missing authorizationEndpoint');
    }
    if (!settings.clientId) {
        throw new Error('[loadSGConnect] Missing clientId');
    }

    const isWindowDefined = typeof window !== 'undefined';
    if (isWindowDefined && !window.sgwtConnect) {
        window.SGWTWidgetConfiguration = {
            ...window.SGWTWidgetConfiguration,
            environment: settings.environment,
        };
    }

    let sgwtConnect;
    if (!isWindowDefined || !window.sgwtConnect) {
        sgwtConnect = setupSGWTConnect({
            authorization_endpoint: settings?.authorizationEndpoint,
            client_id: settings?.clientId,
            scope: settings?.scope,
            acr_values: 'L1',
        });
        if (isWindowDefined) {
            window.sgwtConnect = sgwtConnect;
        }
    } else {
        sgwtConnect = window.sgwtConnect;
    }

    return sgwtConnect;
};

export const setupPassiveMode = (sgwtConnect: SGWTConnectCore) => {
    const widget = document.querySelector('sgwt-connect') as any;
    if (!widget || typeof widget.setSgwtConnectInstance === 'undefined') {
        setTimeout(() => setupPassiveMode(sgwtConnect), 100);
        return;
    }

    widget.setSgwtConnectInstance(sgwtConnect);
};

const getSGConnectSettings = () => {
    return {
        authorizationEndpoint: configuration.authorizationEndpoint,
        clientId: configuration.clientId,
        scope: configuration.scope,
        environment: configuration.sgConnectEnvironment,
    };
};

