import { PictureType } from 'services/ApiService/PictureCollection/PictureCollectionApiClient';

export const createSetQuickSearchTermsAction = (searchTerms?: string) => {
    return {
        type: '@SEARCH/SET_QUICK_SEARCH_TERMS',
        payload: {
            searchTerms,
        },
    } as const;
};

export const createSetQuickSearchPictureTypeAction = (pictureType?: PictureType) => {
    return {
        type: '@SEARCH/SET_QUICK_SEARCH_PICTURE_TYPE',
        payload: {
            pictureType,
        },
    } as const;
};

export const createSetQuickSearchSectorAction = (sectorId?: number) => {
    return {
        type: '@SEARCH/SET_QUICK_SEARCH_SECTOR',
        payload: {
            sectorId,
        },
    } as const;
};

export const createFetchQuickSearchAction = () => {
    return {
        type: '@SEARCH/FETCH_QUICK_SEARCH',
    } as const;
};

export const createFetchQuickSearchSuccessAction = (pictureGroupIds: string[]) => {
    return {
        type: '@SEARCH/FETCH_QUICK_SEARCH_SUCCESS',
        payload: {
            pictureGroupIds,
        },
    } as const;
};

export const createFetchQuickSearchFailureAction = () => {
    return {
        type: '@SEARCH/FETCH_QUICK_SEARCH_FAILURE',
    } as const;
};

export type SearchAction =
    | ReturnType<typeof createSetQuickSearchTermsAction>
    | ReturnType<typeof createSetQuickSearchPictureTypeAction>
    | ReturnType<typeof createSetQuickSearchSectorAction>
    | ReturnType<typeof createFetchQuickSearchAction>
    | ReturnType<typeof createFetchQuickSearchSuccessAction>
    | ReturnType<typeof createFetchQuickSearchFailureAction>;
