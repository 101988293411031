import { Person } from './PictureCollectionApiClient';
import { ObjectsWithoutIdProperty, ObjectsWithoutIdPropertyEnum } from './PictureCollectionApiSchema';

export class BasePictureCollectionApiSchema {
    protected static getId(value: ObjectsWithoutIdProperty, type: ObjectsWithoutIdPropertyEnum): string {
        switch (type) {
            case ObjectsWithoutIdPropertyEnum.Person:
                return (value as Person).icId;

            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const exhaustiveCheck: never = type;
        }

        return '';
    }
}