import { RequiredPictureSize } from 'services/ApiService/PictureCollection/PictureCollectionApiClient';

export const createFetchAppContextAction = () => {
    return {
        type: '@APP_CONTEXT/FETCH_APP_CONTEXT',
    } as const;
};

export const createFetchAppContextSuccessAction = (environment?: string | null, loggedUserId?: string | null, requiredPictureSizes?: RequiredPictureSize[] | null) => {
    return {
        type: '@APP_CONTEXT/FETCH_APP_CONTEXT_SUCCESS',
        payload: {
            environment,
            loggedUserId,
            requiredPictureSizes,
        },
    } as const;
};

export const createFetchAppContextFailureAction = () => {
    return {
        type: '@APP_CONTEXT/FETCH_APP_CONTEXT_FAILURE',
    } as const;
};

export type AppContextAction =
    | ReturnType<typeof createFetchAppContextAction>
    | ReturnType<typeof createFetchAppContextSuccessAction>
    | ReturnType<typeof createFetchAppContextFailureAction>;
