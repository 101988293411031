import { ApiRequest, Reducer } from 'store';

export interface IPictureGroupRequestMap {
    readonly [pictureGroupId: string]: ApiRequest;
}

export const initialState = {
    updateRequest: undefined as ApiRequest | undefined,
    pictureGroups: {} as IPictureGroupRequestMap,
} as const;

export type PicturesState = typeof initialState;

export const PicturesReducer: Reducer<PicturesState> = (state = initialState, action) => {
    switch (action.type) {
        case '@PICTURES/UPDATE_PICTURE_GROUP_REQUEST':
            return {
                ...state,
                updateRequest: {
                    ...state.updateRequest,
                    isFetching: true,
                    didInvalidate: false,
                },
            };

        case '@PICTURES/UPDATE_PICTURE_GROUP_SUCCESS':
            return {
                ...state,
                updateRequest: {
                    ...state.updateRequest,
                    isFetching: false,
                    didInvalidate: false,
                },
            };

        case '@PICTURES/UPDATE_PICTURE_GROUP_FAILURE':
            return {
                ...state,
                updateRequest: {
                    ...state.updateRequest,
                    isFetching: false,
                    didInvalidate: false,
                },
            };

        case '@PICTURES/FETCH_PICTURE_GROUP_REQUEST':
            return {
                ...state,
                pictureGroups: createFetchPictureGroupsState(
                    state,
                    action.payload.pictureGroupIds,
                    {
                        isFetching: true,
                        didInvalidate: true,
                    }),
            };

        case '@PICTURES/FETCH_PICTURE_GROUP_SUCCESS':
            return {
                ...state,
                pictureGroups: createFetchPictureGroupsState(
                    state,
                    action.payload.pictureGroupIds,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    }),
            };

        case '@PICTURES/FETCH_PICTURE_GROUP_FAILURE':
            return {
                ...state,
                pictureGroups: createFetchPictureGroupsState(
                    state,
                    action.payload.pictureGroupIds,
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
            };

        default:
            return state;
    }
};

const createFetchPictureGroupsState = (state: PicturesState, pictureGroupIds: string[], apiRequest: ApiRequest) => {
    let statePictureGroups = {
        ...state.pictureGroups,
    };
    for (const pictureGroupId of pictureGroupIds) {
        statePictureGroups = {
            ...statePictureGroups,
            [pictureGroupId]: {
                ...state.pictureGroups[pictureGroupId],
                ...apiRequest,
            },
        };
    }
    return statePictureGroups;
};
