import { PictureType } from 'services/ApiService/PictureCollection/PictureCollectionApiClient';
import { ApiRequest, Reducer } from 'store';

export const initialState = {
    quickSearch: {
        pictureType: undefined,
        sectorId: undefined,
        searchTerms: undefined,
        data: undefined,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<string[]> & { searchTerms?: string, pictureType?: PictureType, sectorId?: number },
} as const;

export type SearchState = typeof initialState;

export const SearchReducer: Reducer<SearchState> = (state = initialState, action) => {
    switch (action.type) {
        case '@SEARCH/SET_QUICK_SEARCH_TERMS':
            return {
                ...state,
                quickSearch: {
                    ...state.quickSearch,
                    didInvalidate: true,
                    searchTerms: action.payload.searchTerms,
                },
            };

        case '@SEARCH/SET_QUICK_SEARCH_PICTURE_TYPE':
            return {
                ...state,
                quickSearch: {
                    ...state.quickSearch,
                    didInvalidate: true,
                    pictureType: action.payload.pictureType,
                    data: [],
                },
            };

        case '@SEARCH/SET_QUICK_SEARCH_SECTOR':
            return {
                ...state,
                quickSearch: {
                    ...state.quickSearch,
                    didInvalidate: true,
                    pictureType: action.payload.sectorId ? PictureType.Sector : state.quickSearch.pictureType,
                    sectorId: action.payload.sectorId,
                },
            };

        case '@SEARCH/FETCH_QUICK_SEARCH':
            return {
                ...state,
                quickSearch: {
                    ...state.quickSearch,
                    isFetching: true,
                },
            };

        case '@SEARCH/FETCH_QUICK_SEARCH_SUCCESS':
            return {
                ...state,
                quickSearch: {
                    ...state.quickSearch,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.pictureGroupIds,
                },
            };

        case '@SEARCH/FETCH_QUICK_SEARCH_FAILURE':
            return {
                ...state,
                quickSearch: {
                    ...state.quickSearch,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@PICTURES/UPDATE_PICTURE_GROUP_SUCCESS':
            return {
                ...state,
                quickSearch: {
                    ...state.quickSearch,
                    didInvalidate: true,
                },
            };

        default:
            return state;
    }
};
