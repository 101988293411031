import sgLogo from 'images/SGLogo.png';
import SgMarketLogo from 'images/sg_market.svg';
import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler } from 'reactstrap';
import { RoutePaths } from 'routes/RoutePaths';
import { Search } from './Search';
import { SgwtAccountCenter } from './SgwtAccountCenter';
import { useAppContext } from 'components/common/AppProvider';
import classNames from 'classnames';

export const Header: React.FC = () => {
    const { state: { search: { quickSearch: { pictureType, sectorId, searchTerms } } } } = useAppContext();
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const headerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const close = () => setIsOpen(false);
        const clickoutClose = (ev: MouseEvent) => {
            if (ev.target && !headerRef?.current?.contains(ev.target as Node)) {
                close();
            }
        };

        window.addEventListener('click', clickoutClose);
        return () => {
            window.removeEventListener('click', clickoutClose);
        };
    }, []);

    return (
        <header className="header" ref={headerRef}>
            <Navbar id="main-menu" color="white" light style={{ zIndex: 1030 }}>

                <NavLink
                    to={RoutePaths.Home.url(searchTerms, pictureType, sectorId)}
                    className={({ isActive }) => classNames('navbar-brand d-flex align-items-center', { active: isActive })}
                >
                    <img src={sgLogo} width="32" height="32" alt="SG logo" />
                </NavLink>

                <NavbarToggler onClick={toggle} className="d-block d-lg-none me-2" />
                <Search className="d-none d-lg-flex" />

                <div className="flex-fill" />

                <SgwtAccountCenter />

                <svg className="d-none d-md-block overflow-visible ms-3" width="145" height="32">
                    <image xlinkHref={SgMarketLogo} x="0" y="0" height="16" width="145"></image>
                    <text className="font-weight-medium" x="5" y="32" height="16" width="145">
                        Picture Collection
                    </text>
                </svg>
            </Navbar>
            <Collapse isOpen={isOpen} navbar className="d-lg-none">
                <Search className="mx-4" />
            </Collapse>
        </header >
    );
};
