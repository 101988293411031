import { useAppContext } from 'components/common/AppProvider';
import { sortBy } from 'lodash-es';
import React, { useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { PictureType } from 'services/ApiService/PictureCollection/PictureCollectionApiClient';
import { PictureCollectionSelectors } from 'store/Normalizr/Selectors';
import { getSectors } from 'store/Sectors/SectorsThunk';

import 'react-bootstrap-typeahead/css/Typeahead.css';

export interface IPictureTypeSelectorProps {
    id: string;
    className?: string;
    inputClassName?: string;
    pictureType?: PictureType;
    sectorId?: number;
    disabled?: boolean;
    onChange: (pictureType?: PictureType, sectorId?: number) => void;
}

export interface IPictureCategory {
    name: string;
    pictureType?: PictureType;
    sectorId?: number;
}

export const PictureTypeSelector: React.FC<IPictureTypeSelectorProps> = ({ id, className, inputClassName, pictureType, sectorId, disabled, onChange }) => {
    const { dispatch, state } = useAppContext();
    const { entities, sectors: { sectors: sectorsRequest } } = state;
    const sectors = PictureCollectionSelectors.getSectorArray(sectorsRequest?.data, entities.pictureCollection);

    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        (async () => {
            try {
                dispatch(getSectors());
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    if (isLoading) {
        return null;
    }

    const handleChange = (values: Option[]) => {
        const pictureCategory = (values as IPictureCategory[])?.length
            ? (values as IPictureCategory[])[0]
            : undefined;
        onChange(
            pictureCategory?.pictureType || undefined,
            pictureCategory?.sectorId || undefined,
        );
    };

    const categories: IPictureCategory[] = [
        { name: 'Highlights', pictureType: PictureType.Highlights },
        { name: 'Macro', pictureType: PictureType.Macro },
        ...sectors.map(s => ({ name: s.name || '', pictureType: PictureType.Sector, sectorId: s.id })),
    ];
    const selectedCategory = pictureType && categories.find(c => c.pictureType === pictureType
        && (pictureType !== PictureType.Sector || c.sectorId === sectorId));

    return <Typeahead
        id={id}
        className={className}
        clearButton
        paginate={true}
        maxResults={25}
        caseSensitive={false}
        labelKey={l => (l as IPictureCategory).name || ''}
        options={sortBy(categories, ['name'])}
        placeholder="Select a picture type..."
        onChange={handleChange}
        selected={selectedCategory ? [selectedCategory] : []}
        selectHint={(shouldSelect, e) => {
            const key = e.key || e.keyCode;
            return key === 'Enter' || key === 13 || shouldSelect;
        }}
        inputProps={{
            id: 'PictureTypeId',
            className: inputClassName,
            onInput: (e) => !e.currentTarget.value && (e.nativeEvent as InputEvent).inputType !== 'deleteContentBackward' && handleChange([]),
        }}
        highlightOnlyResult={true}
        filterBy={selectedCategory ? () => true : ['name']}
        disabled={disabled}
        multiple={false}
        flip={true}
    />;
};
