import SearchIcon from '@mui/icons-material/Search';
import classNames from 'classnames';
import { ThunkDispatch, useAppContext } from 'components/common/AppProvider';
import { PictureTypeSelector } from 'components/common/PictureTypeSelector';
import { debounce, isArray } from 'lodash-es';
import React, { useEffect, useRef } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RouteParams, RoutePaths } from 'routes/RoutePaths';
import { PictureType } from 'services/ApiService/PictureCollection/PictureCollectionApiClient';
import { createSetQuickSearchPictureTypeAction, createSetQuickSearchSectorAction, createSetQuickSearchTermsAction } from 'store/Search/SearchActions';
import { quickSearch } from 'store/Search/SearchThunk';

export const Search: React.FC<{ className?: string }> = ({ className }) => {
    const { dispatch, state: { search: { quickSearch: { pictureType, sectorId, searchTerms } } } } = useAppContext();
    const searchRef = useRef<HTMLDivElement>(null);
    const searchInput = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { id: pictureGroupId } = useParams<keyof RouteParams['Home']['Preview']>();

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleKeyDown = (event: KeyboardEvent) => {
        const isKeyValid = event.key && event.key.match(/^[a-zA-Z0-9]{1}$/);
        // we listen only if the source is not an input
        if (event.target
            && event.target instanceof Element
            && event.target.tagName.toLocaleLowerCase() !== 'input'
            && event.target.tagName.toLocaleLowerCase() !== 'textarea'
            && isKeyValid
            && !event.altKey
            && !event.ctrlKey) {
            searchInput.current?.focus();
        }
    };

    const synchronizeStateWithSearchQuery = (searchQueryParam: string) => {
        const params = new URLSearchParams(searchQueryParam);

        const searchParam = params.get('search');
        if (!isArray(searchParam) && searchParam !== searchTerms) {
            dispatch(createSetQuickSearchTermsAction(searchParam || undefined));
        }
        const pictureTypeParam = params.get('pictureType');
        if (!isArray(pictureTypeParam) && pictureTypeParam !== pictureType) {
            dispatch(createSetQuickSearchPictureTypeAction((pictureTypeParam as unknown as PictureType) || undefined));
        }
        const sectorIdParam = params.get('sectorId');
        const sectorIdParamNumber = sectorIdParam ? parseInt(sectorIdParam) : undefined;
        const querySectorId = !isArray(sectorIdParamNumber) && sectorIdParamNumber ? sectorIdParamNumber : undefined;
        if (querySectorId !== sectorId) {
            dispatch(createSetQuickSearchSectorAction(querySectorId));
        }
        debouncedQuickSearch(dispatch);
    };

    useEffect(() => {
        synchronizeStateWithSearchQuery(location.search);
    }, [location.search]);

    const updateUrl = (searchTerms?: string, pictureType?: PictureType, sectorId?: number) => {
        if (pictureGroupId) {
            navigate(RoutePaths.Home.Preview.url(pictureGroupId, searchTerms, pictureType, sectorId));
        }
        else {
            navigate(RoutePaths.Home.url(searchTerms, pictureType, sectorId));
        }
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        updateUrl(event.target.value, pictureType, sectorId);
    };

    const handleCategoryChange = (pictureType?: PictureType, sectorId?: number) => {
        updateUrl(searchTerms, pictureType, sectorId);
    };

    return <div className={classNames('flex-fill d-flex', className)} ref={searchRef}>
        <PictureTypeSelector
            id="picture-type-selector"
            inputClassName="form-control-lg"
            onChange={handleCategoryChange}
            pictureType={pictureType}
            sectorId={sectorId}
        />
        <div className="input-group input-group-lg mx-2" style={{ maxWidth: '50em' }}>
            <div className="input-icon-start">
                <SearchIcon fontSize="inherit" />
            </div>
            <input
                ref={searchInput}
                id="search-input"
                type="search"
                className="form-control form-control-xl"
                placeholder="Search by keywords..."
                autoComplete="off"
                defaultValue={searchTerms}
                onChange={handleSearch}
            />
        </div>
        <NavLink to={RoutePaths.Pictures.Add.url(searchTerms, pictureType, sectorId)} className="btn btn-dark btn-lg me-lg-0 flex-shrink-0">
            Add a Picture
        </NavLink>
    </div>;
};

const debouncedQuickSearch = debounce((dispatch: ThunkDispatch) => {
    dispatch(quickSearch()).catch(() => void 0);
}, 300);
