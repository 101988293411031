import {
    LoggedUser,
} from './ApiService/PictureCollection/PictureCollectionApiClient';

export enum Permissions {
    Administrator = 'Administrator',
}

const convertUserPermissions = (user?: LoggedUser | null): Permissions[] => {
    if (!user) {
        return [];
    }

    if (user.permissions && user.permissions.length) {
        return user.permissions.map((up) => up as Permissions);
    }
    return [];
};

export const hasRight = (user?: LoggedUser | null, privilege?: Permissions): boolean => {
    const userPermissions = convertUserPermissions(user);
    if (userPermissions && userPermissions.length) {
        return !!userPermissions.find((up) => up === privilege);
    }
    return false;
};

export const hasAnyRight = (user: LoggedUser | null | undefined, permissions: Permissions[]): boolean => {
    const userPermissions = convertUserPermissions(user);
    if (userPermissions && userPermissions.length) {
        return !!userPermissions.some((up) => permissions.includes(up));
    }
    return false;
};
