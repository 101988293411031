export const createFetchSectorsAction = () => {
    return {
        type: '@SECTOR/FETCH_SECTORS',
    } as const;
};

export const createFetchSectorsSuccessAction = (sectorIds: number[]) => {
    return {
        type: '@SECTOR/FETCH_SECTORS_SUCCESS',
        payload: {
            sectorIds,
        },
    } as const;
};

export const createFetchSectorsFailureAction = () => {
    return {
        type: '@SECTOR/FETCH_SECTORS_FAILURE',
    } as const;
};

export type SectorsAction =
    | ReturnType<typeof createFetchSectorsAction>
    | ReturnType<typeof createFetchSectorsSuccessAction>
    | ReturnType<typeof createFetchSectorsFailureAction>;
