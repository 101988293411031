import { ApiRequest, Reducer } from 'store';

export const initialState = {
    sectors: undefined as ApiRequest<number[]> | undefined,
} as const;

export type SectorsState = typeof initialState;

export const SectorsReducer: Reducer<SectorsState> = (state = initialState, action) => {
    switch (action.type) {
        case '@SECTOR/FETCH_SECTORS':
            return {
                ...state,
                sectors: {
                    ...state.sectors,
                    didInvalidate: true,
                    isFetching: true,
                },
            };

        case '@SECTOR/FETCH_SECTORS_SUCCESS':
            return {
                ...state,
                sectors: {
                    ...state.sectors,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.sectorIds,
                },
            };

        case '@SECTOR/FETCH_SECTORS_FAILURE':
            return {
                ...state,
                sectors: {
                    ...state.sectors,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        default:
            return state;
    }
};
