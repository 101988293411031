import { generatePath } from 'react-router-dom';
import { PictureType } from 'services/ApiService/PictureCollection/PictureCollectionApiClient';

export const RoutePaths = {
    Pictures: {
        Add: {
            route: '/pictures/add',
            url: (search?: string, pictureType?: PictureType, sectorId?: number) =>
                RoutePaths.Pictures.Add.route + getQueryParameters({
                    search,
                    pictureType,
                    sectorId,
                }),
        },
        Edit: {
            route: '/pictures/:id/edit',
            url: (pictureGroupId: string, search?: string, pictureType?: PictureType, sectorId?: number) =>
                generatePath(RoutePaths.Pictures.Edit.route, { id: `${pictureGroupId?.replace(/\//ig, '%2F')}` }) +
                getQueryParameters({ search, pictureType, sectorId }),
        },
    },
    Errors: {
        NotAuthorized: {
            route: '/error/not-authorized',
            url: () => RoutePaths.Errors.NotAuthorized.route,
        },
        NotFound: {
            route: '*',
            url: () => '/error/not-found',
        },
    },
    Home: {
        route: '/',
        url: (search?: string, pictureType?: PictureType, sectorId?: number) =>
            RoutePaths.Home.route + getQueryParameters({
                search,
                pictureType,
                sectorId,
            }),
        Preview: {
            route: '/view/:id',
            url: (pictureGroupId: string, search?: string, pictureType?: PictureType, sectorId?: number) =>
                generatePath(RoutePaths.Home.Preview.route, { id: `${pictureGroupId?.replace(/\//ig, '%2F')}` }) +
                getQueryParameters({ search, pictureType, sectorId }),
        },
    },
} as const;

export type RouteParams = {
    Home: {
        Preview: ExtractRouteParams<typeof RoutePaths.Home.Preview.route, string>,
    },
    Pictures: {
        Add: ExtractRouteParams<typeof RoutePaths.Pictures.Add.route, string>,
        Edit: ExtractRouteParams<typeof RoutePaths.Pictures.Edit.route, string>,
    }
};

const getQueryParameters = (parameters: Record<string, string | number | undefined>) => {
    const search = new URLSearchParams(
        Object.entries(parameters)
            .filter(entry => entry[1])
            .map(([key, value]) => ([key, value?.toString() || ''])),
    ).toString();
    return search ? `?${search}` : '';
};


type ExtractRouteOptionalParam<T extends string, U = string | number | boolean> = T extends `${infer Param}?`
    ? { [_ in Param]?: U }
    : T extends `${infer Param}*`
    ? { [_ in Param]?: U }
    : T extends `${infer Param}+`
    ? { [_ in Param]: U }
    : { [_ in T]: U };

/* eslint-disable */
type ExtractRouteParams<T extends string, U = string | number | boolean> = string extends T
    ? { [_ in string]?: U }
    : T extends `${infer _}:${infer ParamWithOptionalRegExp}/${infer Rest}`
    ? ParamWithOptionalRegExp extends `${infer Param}(${infer _})`
    ? ExtractRouteOptionalParam<Param, U> & ExtractRouteParams<Rest, U>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U> & ExtractRouteParams<Rest, U>
    : T extends `${infer _}:${infer ParamWithOptionalRegExp}`
    ? ParamWithOptionalRegExp extends `${infer Param}(${infer _})`
    ? ExtractRouteOptionalParam<Param, U>
    : ExtractRouteOptionalParam<ParamWithOptionalRegExp, U>
    : unknown;
