import { RequiredPictureSize } from 'services/ApiService/PictureCollection/PictureCollectionApiClient';
import { Reducer } from 'store';

export const initialState = {
    environment: undefined as string | undefined | null,
    loggedUserId: undefined as string | undefined | null,
    requiredPictureSizes: [] as RequiredPictureSize[],
    isFetching: false as boolean,
} as const;

export type AppContextState = typeof initialState;

export const AppContextReducer: Reducer<AppContextState> = (state = initialState, action) => {
    switch (action.type) {
        case '@APP_CONTEXT/FETCH_APP_CONTEXT':
            return {
                ...state,
                isFetching: true,
            };

        case '@APP_CONTEXT/FETCH_APP_CONTEXT_SUCCESS':
            return {
                ...state,
                isFetching: false,
                environment: action.payload.environment,
                loggedUserId: action.payload.loggedUserId,
                requiredPictureSizes: action.payload.requiredPictureSizes || [],
            };

        case '@APP_CONTEXT/FETCH_APP_CONTEXT_FAILURE':
            return {
                ...state,
                isFetching: false,
            };

        default:
            return state;
    }
};
