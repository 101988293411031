import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
export { Dayjs } from 'dayjs';
import i18n from 'i18next';

const defaultTimeZone = 'Europe/Paris';

dayjs.extend(calendar);
dayjs.extend(duration);
dayjs.extend(isToday);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(updateLocale);
dayjs.extend(utc);

dayjs.updateLocale('en', {
    relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: () => 'a min.',
        m: () => 'a min.',
        mm: (number: number) => `${number} min.`,
        h: () => 'an hour',
        hh: (number: number) => `${number} hours`,
        d: () => 'yesterday',
        dd: (number: number) => `${number} days`,
        M: () => '1 month',
        MM: (number: number) => `${number} months`,
        y: () => '1 year',
        yy: (number: number) => `${number} years`,
    },
});

dayjs.updateLocale('fr', {
    relativeTime: {
        future: 'dans %s',
        past: 'il y a %s',
        s: () => 'une min.',
        m: () => 'une min.',
        mm: (number: number) => `${number} min.`,
        h: () => 'une heure',
        hh: (number: number) => `${number} heures`,
        d: () => 'hier',
        dd: (number: number) => `${number} jours`,
        M: () => 'un mois',
        MM: (number: number) => `${number} mois`,
        y: () => 'une année',
        yy: (number: number) => `${number} années`,
    },
});

dayjs.tz.setDefault(defaultTimeZone);

export const isoDateFormat = 'YYYY-MM-DD';
const dateFormat = 'D MMM YYYY';
const timeFormat = 'h:mm A [(PT)]';
const timeSecFormat = 'h:mm:ss A [(PT)]';

const getLanguage = () => i18n.language || 'en';

type DateType = Date | string | number | null | undefined;

export const parseDate = (date: DateType, formatDate?: string, locale: string = getLanguage()) => date && dayjs(date, formatDate || isoDateFormat, locale)?.tz() || undefined;
export const dayjstz = (date: DateType) => dayjs(date)?.tz();
export const dayjsutc = (date: DateType) => dayjs(date)?.utc();

type DisplayDateType = 'Only format' | 'With diff from now' | 'With diff from now without suffix';
export const displayDate = (date: Date, type: DisplayDateType = 'With diff from now without suffix') => {
    if (!date || !dayjstz(date).isValid()) {
        return '';
    }

    if (type === 'With diff from now without suffix' || type == 'With diff from now') {
        const duration = differenceInHours(date, Date.now());
        if (duration <= 0 && duration > -48) {
            return dayjstz(date).locale(getLanguage()).fromNow(type === 'With diff from now without suffix');
        }
    }

    return formatDate(date);
};

export const differenceInDays = (date1: DateType, date2: DateType) => {
    return dayjs(date1).diff(date2, 'day');
};

export const differenceInHours = (date1: DateType, date2: DateType) => {
    return dayjs(date1).diff(date2, 'hour');
};

export const differenceInMs = (date1: DateType, date2: DateType) => {
    return dayjs(date1).diff(date2, 'ms');
};

export const formatDatetime = (date: DateType) => {
    return date && dayjstz(date).locale(getLanguage()).calendar(dayjstz(Date.now()), {
        sameDay: `[today at] ${timeFormat}`,
        nextDay: `[tomorrow at] ${timeFormat}`,
        nextWeek: `dddd [at] ${timeFormat}`,
        lastDay: `[yesterday at] ${timeFormat}`,
        lastWeek: `[last] dddd [at] ${timeFormat}`,
        sameElse: `${dateFormat} [at] ${timeFormat}`,
    });
};

export const formatYear = (date: DateType) => date && dayjstz(date).locale(getLanguage()).format('YYYY') || undefined;

export const formatMonth = (date: DateType) => date && dayjstz(date).locale(getLanguage()).format('MMM') || undefined;

export const formatDay = (date: DateType) => date && dayjstz(date).locale(getLanguage()).format('D') || undefined;

export const formatTime = (date: DateType) => date && dayjstz(date).locale(getLanguage()).format(timeFormat) || undefined;

export const formatTimeSec = (date: DateType) => date && dayjstz(date).locale(getLanguage()).format(timeSecFormat) || undefined;

export const formatDate = (date: DateType, formatDate = dateFormat) => date && dayjstz(date).locale(getLanguage()).format(formatDate) || undefined;

export const formatShortDatetime = (date: DateType) => (date && (dayjstz(date).isToday() ? formatTime(date) : formatDate(date))) || undefined;
