import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ServiceLoader } from 'components/common/Loading';
import { Layout } from 'components/Layout';
import { RoutePaths } from './RoutePaths';

const AddPicture = lazy(() => import('components/Pages/AddPicture/AddPictureLayout'));
const HomeLayout = lazy(() => import('components/Pages/Home/HomeLayout'));
const NotAuthorized = lazy(() => import('components/Pages/Errors/NotAuthorized'));
const NotFound = lazy(() => import('components/Pages/Errors/NotFound'));

export const AppRoutes: React.FC = () => (
    <Layout>
        <Suspense fallback={<div className="d-flex justify-content-center"><ServiceLoader light /></div>}>
            <Routes>
                <Route path={RoutePaths.Home.route} element={<HomeLayout />} />
                <Route path={RoutePaths.Home.Preview.route} element={<HomeLayout />} />
                <Route path={RoutePaths.Pictures.Add.route} element={<AddPicture />} />
                <Route path={RoutePaths.Pictures.Edit.route} element={<AddPicture />} />
                <Route path={RoutePaths.Errors.NotAuthorized.route} element={<NotAuthorized />} />
                <Route path={RoutePaths.Errors.NotFound.route} element={<NotFound />} />
            </Routes>
        </Suspense>
    </Layout>
);
