import { ThunkAction } from 'components/common/AppProvider';
import { normalize } from 'normalizr';
import { PictureCollectionApiSchema, PicturesApi } from 'services/ApiService';
import { PictureGroup } from 'services/ApiService/PictureCollection/PictureCollectionApiClient';
import { abortRequests } from 'services/ApiService/Http';
import { logError } from 'services/Logger';
import { PromiseStore } from 'services/PromiseStore';
import { mergePictureCollectionEntities } from 'store/Normalizr/NormalizrAction';
import {
    createFetchQuickSearchAction,
    createFetchQuickSearchSuccessAction,
    createFetchQuickSearchFailureAction,
} from './SearchActions';

export const quickSearch = (): ThunkAction<Promise<PictureGroup[]>> => async (dispatch, getState) => {
    const state = getState();
    if (!state.search.quickSearch.didInvalidate) {
        const promise = PromiseStore.get<PictureGroup[]>('quickSearch');
        if (promise) {
            return await promise;
        }
    }

    abortRequests('/api/v1/search/quick-search', 'GET');

    const { pictureType, sectorId, searchTerms } = state.search.quickSearch;
    if (!pictureType) {
        return [];
    }

    try {
        const fetchTask = (async () => {
            const data = await PicturesApi.search({
                pictureType,
                sectorId,
                searchTerms,
            });
            const normalizedData = normalize(data.pictures, PictureCollectionApiSchema.PictureGroupSchemaArray);
            dispatch(mergePictureCollectionEntities(normalizedData.entities));
            dispatch(createFetchQuickSearchSuccessAction(normalizedData.result));
            return data.pictures || [];
        })();

        PromiseStore.set(fetchTask, 'quickSearch');

        dispatch(createFetchQuickSearchAction());

        return await fetchTask;
    }
    catch (error) {
        dispatch(createFetchQuickSearchFailureAction());
        logError(error);
        throw error;
    }
};
