import * as React from 'react';
import { getEnvironment, Environment } from 'config/constants';
import { logError } from 'services/Logger';

interface IErrorBoundaryState {
    error: Error | null;
}

export class ErrorBoundary extends React.PureComponent<any, IErrorBoundaryState> {
    public static getDerivedStateFromError(error: Error): { error: Error } {
        return { error };
    }

    constructor(props: any) {
        super(props);
        this.state = {
            error: null,
        };
    }

    public componentDidCatch(error: Error | null): void {
        this.setState({ error });
        if (error) {
            logError(error);
        }
    }

    public render() {
        const { children } = this.props;
        const { error } = this.state;
        if (error) {
            return (
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="alert alert-outline-warning" role="alert">
                        An unknown error has occured. Please contact
                        {' '}
                        <a href="mailto:sgmarkets@sgcib.com" className="alert-link">
                            sgmarkets@sgcib.com
                        </a>
                    </div>
                    <button type="button" className="btn btn-primary" onClick={this.reload}>
                        Reload
                    </button>
                    {this.renderErrorDetails()}
                </div>
            );
        }

        return children;
    }

    private renderErrorDetails() {
        const { error } = this.state;
        const environment = getEnvironment();
        return environment === Environment.Development || environment === Environment.Homologation ? (
            <div>
                <div>
                    {error?.name}
                </div>
                <div>
                    {error?.message}
                </div>
                <pre>
                    {JSON.stringify(error?.stack).replace(/\\n/g, '\n')}
                </pre>
            </div>
        ) : null;
    }

    private reload = (): void => {
        this.setState({
            error: null,
        });
    };
}
